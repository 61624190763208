<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCard class="login-card login-image">
            <CCardBody>
              <CRow>
                <CCol md="6" sm="12">
                  <p class="text-white m-0 h4">{{ getTranslation('Ready to') }}</p>
                  <p class="text-white mt-0 h2 font-weight-bolder">{{ getTranslation('deliver cargo') }}.</p>
                </CCol>
              </CRow>

              <CRow class="justify-content-end margin-top">

                <CCol md="6" sm="12">
                  <h4 class="text-white">Cargo <span class="color-jet font-weight-bold">JET</span> Online</h4>
                  <p class="text-white">Cargo JET is more than logistics. </p>
                </CCol>

                <CCol md="6" sm="12">
                  <CInput
                      placeholder="Email"
                      autocomplete="username email"
                      v-model="form.email"
                      :add-input-classes="{'is-invalid':$v.form.email.$error || errors.email}"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-user"/>
                    </template>
                  </CInput>
                  <CInput
                      placeholder="Password"
                      type="password"
                      autocomplete="curent-password"
                      v-model="form.password"
                      :add-input-classes="{'is-invalid':$v.form.password.$error || errors.password}"
                  >
                    <template #prepend-content>
                      <CIcon name="cil-lock-locked"/>
                    </template>
                  </CInput>
                </CCol>
              </CRow>
              <CRow class="justify-content-end">
                <CCol md="6" sm="12">
                  <CRow>
                    <CCol md="4" sm="12">
                      <CButton @click="login()" color="link" class="px-4 btn-sm bg-white">Login</CButton>
                    </CCol>
                    <CCol md="8" sm="12">
                      <CButton color="link" class="text-white px-0 btn-sm" @click="forgotPassword">Forgot password?</CButton>
                    </CCol>
                  </CRow>
                </CCol>

              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>

import required from "vuelidate/lib/validators/required";

import UserFunctions from "@/classes/UserFunctions";

export default {
  name: "Login",
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      errors: {
        email: false,
        password: false
      }
    }
  },
  methods: {
    forgotPassword() {
      this.$router.push({ name: 'auth.forgotPassword', query: { type: '1' } }).then(() => {
        window.location.reload();
      });
    },
    login() {

      this.$v.$touch();

      this.errors.email = false;
      this.errors.password = false;

      if ( this.$v.$invalid )
        return this.snotifyError('wrongFormMsg');

      this.form.adminka = 1;

      this.axios.post(this.$urls.auth.login.url, this.form).then(async response => {


        UserFunctions.setUserToken(response.data.data.token);

        UserFunctions.setAxiosAuthorization();

        let viewer = response.data.data.viewer;

        // client
        if ( viewer.level.id === 5 ) {

          await this.$store.dispatch('user/setPortalUser', viewer);

          return this.$router.push({ name: 'portal.client.account' }).catch(() => {
          });

        }


        await this.$store.dispatch('user/setUser', viewer);

        if ( this.hasPermission('admin.home') || this.hasPortalPermission('admin.home') )
          return this.$router.push({ name: 'admin.home' }).catch(() => {
          });


        return this.$router.push({ path: '/' }).catch(() => {
        });


      }).catch(() => {
        this.errors.email = true;
        this.errors.password = true;
      });
    }
  },
  validations: {
    form: {
      email: {
        required
      },
      password: {
        required
      }
    }
  }
}
</script>

<style lang="scss">
// Import Main styles for this application
@import '../../../assets/scss/style';
@import "../../../assets/css/custom.css";
@import "../../../assets/css/custom-layout.css";
</style>